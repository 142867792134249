import React from 'react';
import './footer.css';

class Footer extends React.Component {
    render() {
        const terms = "https://ultraculture.org/terms-conditions/";
        const privacy = "https://ultraculture.org/privacy-policy/";
        const cookies = "https://ultraculture.org/cookie-policy/";
        const blank = "_blank";
        return (
            <footer>
                <p>Copyright © 2012–2022 Ultraculture Incorporated · <a href={terms} target={blank}>Terms</a> · <a href={privacy}target={blank}>Privacy Policy</a> · <a href={cookies}target={blank}>Cookie Policy</a></p>
            </footer>
        )
    }
}

export default Footer;