import React from 'react';
import Verticals from './verticals/verticals.js';
import Team from './team/team.js';
import Hiring from './hiring/hiring.js';

class Main extends React.Component {
    render() {
        return (
            <main>
                <Verticals />
                <Team />
                <Hiring />
            </main>
        )
    }
};

export default Main;