import React from 'react';
import './hiring.css';

class Hiring extends React.Component {
    render() {
        return (
            <div id="hiring">
                <div class="hiringInner">
                    <h2>We’re hiring.</h2>
                    <p>Ultraculture is currently hiring for multiple positions. Openings are listed below. Applicants should either reside within the greater Austin area or be willing to telecommute. Salaries are competitive. Ultraculture is an equal opportunity employer.</p>
                    <p>Please check back later for open positions.</p>
                </div>
            </div>
        )
    }
};

export default Hiring;