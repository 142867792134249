import './styles.css';
import Hero from './components/hero/hero.js';
import Main from './components/main/main.js';
import Footer from './components/footer/footer.js';

function App() {
  return (
    <div>
      <Hero />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
