import React from 'react';
import './hero.css';

class Hero extends React.Component {
    render() {
        return (
            <header>
                <div className="outerBox">
                    <div className="innerBox">
                    <div className="col1">
                        <h1>We make magic happen.</h1>
                        <p>Ultraculture Inc is a data-driven media company dedicated to re-imagining the mind / body / spirit marketplace for a global audience. Across our many media verticals, we seek to empower both individuals and companies to show the world their greatest selves. We are based in Austin, Texas, with employees and partners around the world.</p>
                    </div>
                    <div className="col2">

                    </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Hero;