import React from 'react';
import './verticals.css';
import { Route } from 'react-router-dom';

class Verticals extends React.Component {
    render() {
        const magickme = "window.location.href='https://www.magick.me';";
        const ultraculture = "window.location.href='https://www.ultraculture.org';";
        const btcinsider = "window.location.href='https://www.bitcoininsider.io';";
        return (
            <div id="verticals">
                <div className="verticals-copy">
                    <h2>Ultraculture Inc: A New Kind of Media Company</h2>
                    <p>With media properties and collaborations ranging across the digital, print, film and broadcast domains, Ultraculture is carving out a new kind of media empire by bringing together the best of traditional spirituality and cutting-edge technology. Here’s just a few of our current projects:</p>
                </div>
                <div className="verticals-flex">
                    <div className="verticals-item">
                        <h3>MAGICK.ME</h3>
                        <p>Our entry into the thriving global online education marketplace, now in its fifth year of enlightening and empowering students worldwide.</p>
                        <button
                            onclick={magickme}
                        >Learn More</button>
                    </div>
                    <div className="verticals-item">
                        <h3>ULTRACULTURE</h3>
                        <p>Our flagship media property, Ultraculture is a blog, publishing company and podcast dedicated to reimagining spirituality for the 21st century.</p>
                        <button
                            onclick={ultraculture}
                        >Learn More</button>
                    </div>
                    <div className="verticals-item">
                        <h3>BITCOIN INSIDER</h3>
                        <p>Our pioneer resource for fintech education, bringing a comprehensive understanding of blockchain technology to everyone.</p>
                        <button
                            onclick={btcinsider}
                        >Learn More</button>
                    </div>
                </div>
            </div>
        )
    }
};

export default Verticals;