import React from 'react';
import './team.css';

class Team extends React.Component {
    render() {
        return (
            <div id="team">
                <div className="teamOuter">
                    <h2>Our Team</h2>
                    <p>With backgrounds and experience spanning the entire range of traditional and digital media and advertising, our fast-growing startup team is dedicated to bringing a little magic to the world. We are creatives, innovators, data scientists, systems hackers and dreamers—and, first and foremost, family. We embrace the experimental but always data-driven approach of startup culture, and strive to constantly imagine new models, systems and products just beyond the edge of manifestation.</p>
                    <p>We are proud to be job providers in the middle of the COVID-19 crisis, and are looking for driven and passionate leaders to join our team. Please see below for currently open positions.</p>
                </div>
            </div>
        )
    }
}

export default Team;